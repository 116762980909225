
import { Vue, Component } from "vue-property-decorator";
import { apiGetlang, apiSetlang } from "@/api/setting";
@Component({
  components: {},
})
export default class StorageIndex extends Vue {
  list = [];

  // 获取语言列表
  getLangList() {
    apiGetlang({})
      .then((res: any) => {
        this.list = res;
      })
      .catch(() => {
        this.$message.error("获取数据失败，请重试！");
      });
  }
  // 切换语言
  langChange(item: any) {
    apiSetlang({ id: item.id, status: item.status })
      .then((res: any) => {
        this.getLangList();
        this.$message.success("更新成功");
      })
      .catch(() => {
        this.$message.error("更新失败");
      });
  }

  created() {
    this.getLangList();
  }
}
